<template>
  <div>
    <b-card
      v-for="item in data"
      :key="item.icon"
      v-b-tooltip.hover.top="icon"
      bg-variant="transparent"
      class="shadow-none icon-card cursor-pointer text-center mb-2 mx-50"
    >
      <div class="icon-wrapper">
        <b-avatar
          size="150"
          :variant="item.color"
        >
          <feather-icon
            :color="item.coloricon"
            :icon="item.icon"
            size="50"
          />
        </b-avatar>
      </div>
      <h3 class="icon-name text-truncate mb-0 mt-1">
        {{ item.title }}
      </h3>
    </b-card>
  </div>
<!--
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
    style="background-color: #F8F8F8"
  >
    <b-card-body>
      <div
        v-for="item in data"
        id="colEstyle"
        :key="item.icon"
        :class="item.customClass"
      >
        <b-media>
          <b-media-aside>
            <b-avatar
              size="150"
              :variant="item.color"
            >
              <feather-icon
                size="60"
                :color="item.coloricon"
                :icon="item.icon"
              />
            </b-avatar>
          </b-media-aside>
        </b-media>
        <h3 style="color: black; text-align: center; margin-top: 20px;">
          {{ item.title }}
        </h3>
      </div>
    </b-card-body>
  </b-card>
  -->
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
    data: {
      type: Array,
      default: () => [
      ],
    },
  },
}
</script>
<style scoped>
#colEstyle {
  padding: 25px;
}
.icon-wrapper .feather {
    height: 50px;
    width: 50px;
}
</style>
