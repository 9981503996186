import axios from '@axios'

export default function speakerService() {
  const verifyUserLive = async (queryParams) => {
    const res = await axios.get('/spekaer/lives', { params: queryParams })
    console.log(res)
    return res.data
  }

  const getLives = async (queryParams) => {
    const res = await axios.get('/lives', { params: queryParams })
    console.log(res)
    return res.data.response.lives
  }

  return {
    verifyUserLive,
    getLives,
  }
}
